import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Observable, Subscription, combineLatest, find, map, startWith } from 'rxjs';
import { Collector } from '../models/collector.model';
import { Vendor } from '../models/vendor.model';
import { CollectorService } from '../services/collector.service';
import { CollectLocation } from '../models/location.model';
import { WasteType } from '../models/waste_type.model';
import { Container } from '../models/container.model';
import { LocationService } from '../services/location.service';
import { ScaleService } from '../services/scale_service';
import { ContainerService } from '../services/container.service';
import { AccountService } from '../services/account.service';
import { WasteTypeService } from '../services/waste-type.service';
import { CollectService } from '../services/collect.service';
import { VendorService } from '../services/vendor.service';
import { CollectInput } from '../models/collects.model';
import * as Units from '../units';

@Component({
  selector: 'update-last-collect',
  templateUrl: './update-last-collect.component.html',
  styleUrls: ['./update-last-collect.component.scss']
})

export class UpdateLastCollectComponent implements OnInit {
  @ViewChild('form', {static: false}) updateLastCollectNgForm: NgForm;

  form = new FormGroup({
    location: new FormControl<string>(null, [Validators.required, this.getLocationValidator()]),
    waste_type: new FormControl<string>(null, [Validators.required, this.getWasteTypeValidator()]),
    collector: new FormControl<string>(null, [Validators.required, this.getCollectorValidator()]),
    vendor: new FormControl<string>(null, [Validators.required, this.getVendorValidator()]),
    container: new FormControl<string>(null, [Validators.required, this.getContainerValidator()]),
    tare_weight: new FormControl<number>(null, [Validators.required, Validators.min(0)]),
    scale_weight: new FormControl<number>(0, [Validators.required, Validators.min(0)]),
    net_weight: new FormControl<number>(0, [Validators.required, this.getNetWeightValidator()]),
    volume: new FormControl<number>(0),
    weighed_at: new FormControl<string>("", [Validators.required, this.getWeighedAtValidator()])
  });

  scale_weight: number = 0;
  tare_weight: number = 0;
  usingVolume = false;

  private formOpenedAt: string;

  // Observables
  public scaleValue$: number;
  filteredCollectLocations$: Observable<CollectLocation[]>;
  filteredWaste_Types$: Observable<WasteType[]>;
  filteredCollectors$: Observable<Collector[]>;
  filteredVendors$: Observable<Vendor[]>;
  filteredContainers$: Observable<Container[]>;

  // Subscriptions
  scaleSubscription: Subscription = null;
  scaleWeightSubscription: Subscription = null;
  tareWeightSubscription: Subscription = null;
  tareSubscription: Subscription = null;
  volumeSubscription: Subscription = null;

  // "idle" indicates the user has not tried to submit the request yet
  // "success" indicates the request was successfully submitted
  // "error" indicates the request was not successfully submitted
  submissionState: "idle" | "success" | "error" = "idle";

  // Validators
  getLocationValidator(): ValidatorFn {
    return (control: FormGroup): ValidationErrors | null => {
      const included = this.ls.cachedLocations.some((x) => x.name === control.value)
      return included ? null : { unknownValue: { value: control.value } };
    };
  };

  getWasteTypeValidator(): ValidatorFn {
    return (control: FormGroup): ValidationErrors | null => {
      const included = this.wts.cachedWaste_Types.some((x) => x.name === control.value)
      return included ? null : { unknownValue: { value: control.value } };
    };
  };

  getCollectorValidator(): ValidatorFn {
    return (control: FormGroup): ValidationErrors | null => {
      const included = this.collrs.cachedCollectors.some((x) => x.name === control.value)
      return included ? null : { unknownValue: { value: control.value } };
    };
  };

  getVendorValidator(): ValidatorFn {
    return (control: FormGroup): ValidationErrors | null => {
      const included = this.vendors.cachedVendors.some((x) => x.name === control.value)
      return included ? null : { unknownValue: { value: control.value } };
    };
  };

  getContainerValidator(): ValidatorFn {
    return (control: FormGroup): ValidationErrors | null => {
      const included = this.conts.cachedContainers.some((x) => x.name === control.value)
      return included ? null : { unknownValue: { value: control.value } };
    };
  };

  getWeighedAtValidator(): ValidatorFn {
    return (control: FormGroup): ValidationErrors | null => {
      const withinRange = control.value < this.formOpenedAt;
      return withinRange ? null : { max: { value: control.value } }
    }
  }

  getNetWeightValidator(): ValidatorFn {
    return (control: FormGroup<number>): ValidationErrors | null => {
      if (this.usingVolume) {
        return null;
      }

      return control.value >= 0 ? null : { min: { min: 0, actual: control.value } };
    };
  }

  constructor(
    public ls: LocationService,
    public ss: ScaleService,
    public conts: ContainerService,
    public us: AccountService,
    public wts: WasteTypeService,
    public collrs: CollectorService,
    public vendors: VendorService,
    private cs: CollectService
  ) {
    const tzoffset = (new Date()).getTimezoneOffset() * 60000; // offset in milliseconds
    // Slice the trailing "Z" off the end of the string because this is not UTC
    this.formOpenedAt = new Date(Date.now() - tzoffset).toISOString().slice(0, -1);
  }

  ngOnInit() {
    // volume is always disabled, and will only be set programmatically when the container changes
    this.form.controls.volume.disable();

    this.filteredCollectLocations$ = combineLatest(
      this.form.controls.location.valueChanges.pipe(
        startWith('')),
        this.ls.collectLocations,
      )
      .pipe(
      map(([value, collectLocations]) => {
        const filtered = value ? this._filteredCollectLocations(value, collectLocations) : collectLocations;
        return filtered.sort((a, b) => (a.floor > b.floor) ? 1 : (a.floor === b.floor) ? ((a.name > b.name) ? 1 : -1) : -1 );
      })
    );

    this.filteredWaste_Types$ = combineLatest(
      this.form.controls.waste_type.valueChanges
      .pipe(
        startWith('')),
        this.wts.waste_types,
      )
      .pipe(
        map(([value, waste_types]) => {
          const filtered = value ? this._filteredWasteTypes(value, waste_types) : waste_types;
          return filtered;
      })
    );

    this.filteredCollectors$ = combineLatest(
      this.form.controls.collector.valueChanges
      .pipe(
        startWith('')),
        this.collrs.collectors,
      )
      .pipe(
        map(([value, collectors]) => {
          const filtered = value ? this._filteredCollectors(value, collectors) : collectors;
          return filtered;
      })
    );

    this.filteredVendors$ = combineLatest(
      this.form.controls.vendor.valueChanges
      .pipe(
        startWith('')),
        this.vendors.vendors,
      )
      .pipe(
        map(([value, vendors]) => {
          const filtered = value ? this._filteredVendors(value, vendors) : vendors;
          return filtered;
      })
    );

    this.filteredContainers$ = combineLatest(
      this.form.controls.container.valueChanges
      .pipe(
        startWith('')),
        this.conts.containers,
      )
      .pipe(
        map(([value, containers]) => {
          const filtered = value ? this._filteredContainers(value, containers) : containers;
          return filtered;
      })
    );

    this.initFormWithLastCollect();

    if (this.usingVolume) {
      this.updateVolume();
    } else {
      this.updateNetWeight();
      this.updateTare();
      this.getScaleUnit();
      // Since subscriptions were set up after values were initialized
      // from the form, we need to trigger these controls now
      this.form.controls.scale_weight.updateValueAndValidity();
      this.form.controls.tare_weight.updateValueAndValidity();
    }
  }

  private initFormWithLastCollect() {
    const collect = this.cs.lastSavedCollect;
    if (!collect) {
      return;
    }

    this.usingVolume = collect[1].measurement_type === "volume";

    const location = collect[1].location_name;
    this.form.controls["location"].setValue(location);

    const waste_type = collect[1].waste_type_name;
    this.form.controls["waste_type"].setValue(waste_type);

    const collector = collect[1].collector_name;
    this.form.controls["collector"].setValue(collector);

    const vendor = collect[1].vendor_name;
    this.form.controls["vendor"].setValue(vendor);

    const container = collect[1].container_name;
    this.form.controls["container"].setValue(container);

    const tare_weight = collect[1].tare_weight;
    this.form.controls["tare_weight"].setValue(tare_weight);

    const scale_weight = collect[1].scale_weight;
    this.form.controls["scale_weight"].setValue(scale_weight);

    this.form.controls["net_weight"].setValue((scale_weight - tare_weight));

    this.form.controls["volume"].setValue(collect[1].target_quantity);

    var tzoffset = (new Date()).getTimezoneOffset() * 60000; // offset in milliseconds
    // weighed_at on the collect is Unix time in seconds
    const weighed_at_ms = collect[1].weighed_at * 1000
    // Slice the trailing "Z" off the end of the string because this is not UTC
    let weighed_at = new Date(weighed_at_ms - tzoffset).toISOString().slice(0, -1);
    this.form.controls["weighed_at"].setValue(weighed_at);
  }

  getScaleUnit() {
    let scale_unit = "lbs";
    return scale_unit;
  }

  updateNetWeight(): void {
    this.scaleWeightSubscription = this.form.get('scale_weight').valueChanges
      .subscribe((scale: number) => {
        this.scale_weight = scale;
        this.form.controls['net_weight'].setValue(Math.round((this.scale_weight - this.tare_weight)*100)/100);
      });
    this.tareWeightSubscription = this.form.get('tare_weight').valueChanges
      .subscribe((container: number) => {
        this.tare_weight = container;
        this.form.controls['net_weight'].setValue(Math.round((this.scale_weight - this.tare_weight)*100)/100);
      });
  }

  updateTare() {
    this.tareSubscription = this.form.controls['container'].valueChanges
      .subscribe(containerNameChange => {
        const container = this.conts.getContainerByName(containerNameChange);
        if (container) {
          this.form.controls['tare_weight'].setValue(container.tare_weight);
        }
      });
  }

  updateVolume() {
    this.volumeSubscription = this.form.get('container').valueChanges
      .subscribe((containerName) => {
        const container = this.conts.getContainerByName(containerName);
        if (container) {
          this.form.controls['volume'].setValue(container.volume);
        }
      });
  }

  // Autocomplete Filters
  private _filteredCollectLocations(value: string, collectLocations: CollectLocation[]): CollectLocation[] {
    const filterValue = value.toLowerCase();
    return collectLocations.filter(collectLocation => collectLocation.name.toLowerCase().indexOf(filterValue) === 0);
  }

  private _filteredWasteTypes(value: string, waste_types: WasteType[]): WasteType[] {
    const filterValue = value.toLowerCase();
    return waste_types.filter(waste_type => waste_type.name.toLowerCase().indexOf(filterValue) === 0);
  }

  private _filteredCollectors(value: string, collectors: Collector[]): Collector[] {
    const filterValue = value.toLowerCase();
    return collectors.filter(collector => collector.name.toLowerCase().indexOf(filterValue) === 0);
  }

  private _filteredVendors(value: string, vendors: Vendor[]): Vendor[] {
    const filterValue = value.toLowerCase();
    return vendors.filter(vender => vender.name.toLowerCase().indexOf(filterValue) === 0);
  }

  private _filteredContainers(value: string, containers: Container[]): Container[] {
    const filterValue = value.toLowerCase();
    return containers.filter(container => container.name.toLowerCase().indexOf(filterValue) === 0);
  }

  getCollectFromForm() {
    // `getRawValue` instead of just `value` because the volume field is disabled and not included in `value`
    const values = this.form.getRawValue();

    const weighed_at = Date.parse(values.weighed_at);

    const container = this.conts.cachedContainers.find((x) => x.name === values.container);

    const targetQuantity = this.usingVolume ? values.volume : values.net_weight;

    const collect: CollectInput = {
      container_id: this.conts.idFromContainerName(values.container),
      location_id: this.ls.idFromLocationName(values.location),
      waste_type_id: this.wts.idFromWaste_TypeName(values.waste_type),
      net_weight: values.net_weight,
      collector_id: this.collrs.idFromCollectorName(values.collector),
      vendor_id: this.vendors.idFromVendorName(values.vendor),
      scale_weight: values.scale_weight,
      tare_weight: values.tare_weight,
      measurement_type: this.usingVolume ? "volume" : "weight",
      target_unit: this.usingVolume ? container.volume_unit : Units.LB,
      target_quantity: targetQuantity,
      net_weight_from_volume: this.usingVolume ? this.weightFromVolume(values.volume) : null,
      weighed_at: weighed_at / 1000, // Server is expecting unix seconds, not milliseconds
    }

    return collect;
  }

  async onSubmit() {
    const collect = this.getCollectFromForm();
    try {
      await this.cs.updateLastCollect(collect);
      this.submissionState = "success";
    } catch(err) {
      this.submissionState = "error";
      console.error(err);
    }

    this.form.reset();
  }

  async deleteLastCollect() {
    try {
      await this.cs.deleteLastCollect();
      this.submissionState = "success";
    } catch(err) {
      this.submissionState = "error";
      console.error(err);
    }
  }

  containerUnitLabel() {
    const controls = this.form.controls;
    const container = this.conts.cachedContainers.find((x) => x.name === controls.container.value);
    if (container) {
      return Units.getLabel(container.volume_unit);
    }
  }


  weightFromVolume(volume: number) {
    const controls = this.form.controls;
    const container = this.conts.cachedContainers.find((x) => x.name === controls.container.value);
    const wasteType = this.wts.cachedWaste_Types.find((x) => x.name === controls.waste_type.value);

    if (!container) {
      throw("weightFromVolume: no container is selected");
    }
    if (!wasteType) {
      throw("weightFromVolume: no wasteType is selected");
    }

    const weightPerVolume = wasteType.conversion_weight / wasteType.conversion_volume;
    const wasteTypeUnitsPerContainerUnit = Units.getVolumeRatio(
      wasteType.conversion_volume_unit,
      container.volume_unit,
    )

    return volume * wasteTypeUnitsPerContainerUnit * weightPerVolume;
  }

  ngOnDestroy(){
    this.scaleSubscription?.unsubscribe();
    this.scaleWeightSubscription?.unsubscribe();
    this.tareWeightSubscription?.unsubscribe();
    this.tareSubscription?.unsubscribe();
  }
}
