import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ApiService } from '../services/api.service';
import { Observable, combineLatest, map, startWith } from 'rxjs';
import { Collector } from '../models/collector.model';
import { CollectorService } from '../services/collector.service';

@Component({
  selector: 'support-request',
  templateUrl: './support-request.component.html',
  styleUrls: ['./support-request.component.scss']
})

export class SupportRequestComponent implements OnInit {
  @ViewChild('form', {static: false}) supportRequestNgForm: NgForm;

  supportRequestForm = new FormGroup({
    // collector is not required, but if one is provided then it has
    // to be a known value from the collectorService.
    collector: new FormControl<string | null>(null, [this.getCollectorValidator()]),
    content: new FormControl<string>("", [Validators.required, Validators.min(0)])
  });

  // "idle" indicates the user has not tried to submit the request yet
  // "success" indicates the request was successfully submitted
  // "error" indicates the request was not successfully submitted
  submissionState: "idle" | "success" | "error" = "idle";

  // Observables
  filteredCollectors$: Observable<Collector[]>;

  // Validators
  getCollectorValidator(): ValidatorFn {
    return (control: FormGroup): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      const included = this.collectorService.cachedCollectors.some((x) => x.name === control.value)
      return included ? null : { unknownValue: { value: control.value } };
    };
  };

  constructor(
    private collectorService: CollectorService,
    private apiService: ApiService
  ) {}

  ngOnInit() {
    this.filteredCollectors$ = combineLatest(
      this.supportRequestForm.controls.collector.valueChanges
      .pipe(
        startWith('')), // TODO: What does this do? Can it be removed?
        this.collectorService.collectors,
      )
      .pipe(
        map(([input, collectors]) => {
          if (input) {
            return collectors.filter(collector => (
              collector.name.toLowerCase().startsWith(input.toLowerCase())
            ));
          } else {
            return collectors;
          }
        })
    );
  }

  async onSubmit() {
    const content = this.supportRequestForm.value.content;

    const collector_name = this.supportRequestForm.value.collector;
    const collector_id = this.collectorService.idFromCollectorName(collector_name) || null;

    const supportRequest = { content, collector_id };

    try {
      await this.apiService.submitSupportRequest(supportRequest).toPromise();
      this.submissionState = "success";
      this.supportRequestForm.reset();
    } catch(err) {
      console.error(err);
      this.submissionState = "error";
    }
  }
}
