import { Component, OnInit } from '@angular/core';
import firebase from 'firebase/compat/app';
import * as firebaseui from 'firebaseui'
import { AccountService } from '../../services/account.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-email-login',
  templateUrl: './email-login.component.html',
  styleUrls: ['./email-login.component.scss']
})

export class EmailLoginComponent implements OnInit {
  private _firebaseui: firebaseui.auth.AuthUI;

  constructor(public accountService: AccountService) { }

  ngOnInit() {
    var uiConfig: firebaseui.auth.Config = {
      callbacks: {
        // Returning false here allows us to not provide a signInSuccessUrl
        // to the uiConfig. That way, the account service can redirect to
        // the collect form once it's finished authenticating with the Rails server.
        signInSuccessWithAuthResult: () => false,
      },
      signInOptions: [ firebase.auth.EmailAuthProvider.PROVIDER_ID ],
      // Let the firebaseui library know that we've disabled the ability
      // for users to create their own accounts:
      adminRestrictedOperation: {
        status: true,
      }
    };

    const firebaseAuth = firebase.auth(this.accountService.firebaseApp);
    this._firebaseui = new firebaseui.auth.AuthUI(firebaseAuth);
    // The start method will wait until the DOM is loaded.
    this._firebaseui.start('#firebaseui-auth-container', uiConfig);
  }

  ngOnDestroy() {
    this._firebaseui.delete();
  }
}
