import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import * as Sentry from "@sentry/angular-ivy";

import { CollectModule } from './collect/collect.module';
import { UpdateLastCollectModule } from './update-last-collect/update-last-collect.module';
import { ReportsModule } from './reports/reports.module';
import { SupportRequestModule } from './support-request/support-request.module';
import { UiModule } from './ui/ui.module';
import { AccountModule } from './account/account.module';

import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { WebUsbComponent } from './web-usb/web-usb.component';
import { FocusInvalidInputDirective } from './directives/focus-invalid-input.directive';
import { AutofocusDirective } from './directives/autofocus.directive';
import { HomeComponent } from './home/home.component';
import { Router } from '@angular/router';

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    WebUsbComponent,
    FocusInvalidInputDirective,
    AutofocusDirective,
    HomeComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CollectModule,
    UpdateLastCollectModule,
    FormsModule,
    HttpClientModule,
    UiModule,
    AccountModule,
    ReportsModule,
    SupportRequestModule,
    ReactiveFormsModule
  ],
  exports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CollectModule,
    UpdateLastCollectModule,
    FormsModule,
    HttpClientModule,
    UiModule,
    AccountModule,
    ReportsModule,
    SupportRequestModule,
    ReactiveFormsModule
    ],
  bootstrap: [ AppComponent ],
  providers: [{
    provide: ErrorHandler,
    useValue: Sentry.createErrorHandler({
      showDialog: false,
    }),
  }, {
    provide: Sentry.TraceService,
    deps: [Router],
  },
  {
    provide: APP_INITIALIZER,
    useFactory: () => () => {},
    deps: [Sentry.TraceService],
    multi: true,
  },
],
})

export class AppModule { }
