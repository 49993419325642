import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ScaleWeight } from '../models/scale.model';
import { AccountService } from './account.service';
import { Account } from '../models/account.model';

@Injectable({
  providedIn: 'root'
})

export class ScaleService {
  private serialPort: SerialPort | undefined;
  private scaleValue: BehaviorSubject<ScaleWeight> = new BehaviorSubject({ scale: 0 });
  private currentAccountSubscription: Subscription = null;
  private requestingDisconnection = false;

  constructor(public http: HttpClient, private accountService: AccountService) {}

  connectOrDisconnectFromSerialPort() {
    if (this.isConnected()) {
      if (confirm("Disconnect scale?")) {
        this.requestingDisconnection = true;
      }
    } else {
      this.currentAccountSubscription = this.accountService.currentAccount.subscribe(account => {
        if (account) {
          navigator.serial.requestPort()
            .then((port) => {
              this.serialPort = port;
              this.connectToScale(account);
            })
            .catch((error) => {
              console.warn(error);
            });
        }
      });
    }
  };

  ngOnDestroy() {
    this.currentAccountSubscription?.unsubscribe();
  }

  async connectToScale(account: Account) {
    if (!this.serialPort) {
      console.warn("serialPort not set");
      return;
    }

    const scale = account.building.scale;
    if (!scale) {
      console.warn("Account has no scale");
      return;
    }

    console.log("Connecting to scale: ", scale);
    const regex = new RegExp(scale.regex);

    console.log("Opening serialPort...");
    try {
      await this.serialPort.open({
        baudRate: scale.baud_rate,
        dataBits: scale.data_bit,
        stopBits: scale.stop_bit,
        bufferSize: scale.buffer_size,
        flowControl: "none",
      });
    } catch(e) {
      console.warn("Failed to open serial port", e);
      alert("Failed to connect");
      this.serialPort = undefined;
      return;
    }
    console.log("Successfully opened serialPort");

    const bufferSize = scale.buffer_size;
    const eolChar = scale.eol_char;
    const outerBuffer: number[] = [];

    this.readLoop(bufferSize, (chunk: Uint8Array) => {
      outerBuffer.push(...chunk);
      const endOfLineIndex = outerBuffer.findIndex((n) => n === eolChar);
      if (endOfLineIndex !== -1) {
        const line = outerBuffer.splice(0, endOfLineIndex + 1);
        const string = new TextDecoder().decode(new Uint8Array(line));
        const result = regex.exec(string);
        if (result) {
          const scaleWeight: ScaleWeight = { scale: parseInt(result[0]) };
          this.scaleValue.next(scaleWeight);
        }
      }
    });
  }

  public isConnected() {
    return !!this.serialPort;
  }

  public getScaleWeight(): Observable<number>{
    return this.scaleValue.asObservable().pipe(map((scaleWeight) => scaleWeight.scale));
  }

  // This implementation is roughly based on:
  // https://github.com/GoogleChromeLabs/serial-terminal/blob/main/src/index.ts
  private readLoop = (bufferSize: number, callback: (output: Uint8Array) => void) => {
    let reader: ReadableStreamDefaultReader | ReadableStreamBYOBReader | undefined;

    const iterate = async () => {
      try {
        try {
          reader = this.serialPort.readable.getReader({mode: 'byob'});
        } catch {
          reader = this.serialPort.readable.getReader();
        }
  
        let buffer = null;
        for (;;) {
          const {value, done} = await (async () => {
            if (reader instanceof ReadableStreamBYOBReader) {
              if (!buffer) {
                buffer = new ArrayBuffer(bufferSize);
              }
              const {value, done} = await reader.read(new Uint8Array(buffer, 0, bufferSize));
              buffer = value?.buffer;
              return {value, done};
            } else {
              const readResult = await reader.read();
              return readResult;
            }
          })();
  
          if (value) {
            await new Promise<void>((resolve) => {
              callback(value);
              resolve();
            });
          }
          if (done || this.requestingDisconnection) {
            this.requestingDisconnection = false;
            break;
          }
        }
      } catch (e) {
        console.error(e);
        throw e;
      } finally {
        if (reader) {
          reader.releaseLock();
          reader = undefined;
        }
      }
    }

    const loop = new Promise(async () => {
      while (this.serialPort && this.serialPort.readable) {
        try {
          await iterate();
          await this.serialPort.close();
          this.serialPort = undefined;
        } catch(e) {
          console.error(e);
          break;
        }
      }
    });
    loop.then(() => console.log("Finished readLoop"));
  };
}
