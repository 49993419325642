import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { SupportRequestComponent } from '../../support-request/support-request.component';
import { UpdateLastCollectComponent } from '../../update-last-collect/update-last-collect.component';
import { NetworkService } from '../../services/network.service';
import { AccountService } from '../../services/account.service';
import { CollectService } from '../../services/collect.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(
    private router: Router,
    private collectService: CollectService,
    public accountService: AccountService,
    public networkService: NetworkService,
    public dialog: MatDialog,
  ) { }

  show = false;

  ngOnInit() {
  }

  toggleCollapse() {
    this.show = !this.show;
  }

  disableUpdateLastCollect() {
    return !this.collectService.lastSavedCollect;
  }

  signOut() {
    // No need to unsubscribe
    this.accountService.signOut().subscribe(() => {
      this.router.navigate(['/login'])
    });
  } 

  openSupportRequestModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = true;
    dialogConfig.width = "90%";
    dialogConfig.maxWidth = "40rem";
    dialogConfig.closeOnNavigation = true;
    this.dialog.open(SupportRequestComponent, dialogConfig);
  }

  openUpdateLastCollectModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = true;
    dialogConfig.width = "100%";
    dialogConfig.maxWidth = "70rem";
    dialogConfig.closeOnNavigation = true;
    this.dialog.open(UpdateLastCollectComponent, dialogConfig);
  }
}
