import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { NgForm, ValidationErrors, Validators, FormGroup, FormControl, ValidatorFn } from '@angular/forms';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { map, startWith } from "rxjs/operators";
import { format } from "date-fns";

// Services
import { CollectService } from '../services/collect.service';
import { LocationService } from '../services/location.service';
import { ScaleService } from '../services/scale_service';
import { ContainerService } from '../services/container.service';
import { AccountService } from '../services/account.service';
import { WasteTypeService } from '../services/waste-type.service';
import { CollectorService } from '../services/collector.service';
import { VendorService } from '../services/vendor.service';

// Components
import { CollectedComponent } from './collected/collected.component';
import { QueuedComponent } from './queued/queued.component';

// Models
import { CollectLocation } from '../models/location.model';
import { WasteType } from '../models/waste_type.model';
import { Collector } from '../models/collector.model';
import { Vendor } from '../models/vendor.model';
import { Container } from '../models/container.model';
import { CollectInput } from '../models/collects.model';
import * as Units from '../units';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { getVolumeRatio, getLabel } from '../units';

type CollectFormGroup = FormGroup<{
  collect_location: FormControl<string>,
  container: FormControl<string>,
  waste_type: FormControl<string>,
  collector: FormControl<string>,
  vendor: FormControl<string>,
  net_weight: FormControl<number>,
  tare_weight: FormControl<number>,
  scale_weight: FormControl<number>,
  volume: FormControl<number>,
  weighed_at: FormControl<string>,
}>

@Component({
  selector: 'app-collect',
  templateUrl: './collect.component.html',
  styleUrls: ['./collect.component.scss']
})

export class CollectComponent implements OnInit {

    @ViewChild('form', {static: false}) collectNgForm: NgForm;

    collectForm = new FormGroup({
      collect_location: new FormControl<string>(null, [Validators.required, this.getLocationValidator()]),
      waste_type: new FormControl<string>(null, [Validators.required, this.getWasteTypeValidator()]),
      collector: new FormControl<string>(null, [Validators.required, this.getCollectorValidator()]),
      vendor: new FormControl<string>(null, [Validators.required, this.getVendorValidator()]),
      container: new FormControl<string>(null, [Validators.required, this.getContainerValidator()]),
      tare_weight: new FormControl<number>(null, [Validators.required, Validators.min(0)]),
      scale_weight: new FormControl<number>(0, [Validators.required, Validators.min(0)]),
      net_weight: new FormControl<number>(0, [Validators.required, this.getNetWeightValidator()]),
      volume: new FormControl<number>(0),
      weighed_at: new FormControl<string>("", [this.getWeighedAtValidator()]),
    });

    scale_weight: number = 0;
    tare_weight: number = 0;
    net_weight: number = 0;
    volume: number | null = null;
    container: number = 0;
    weighedAtContainerExpanded = false;
    usingVolume = false;

    // Observables
    public scaleValue$: number;
    filteredCollectLocations$: Observable<CollectLocation[]>;
    filteredWaste_Types$: Observable<WasteType[]>;
    filteredCollectors$: Observable<Collector[]>;
    filteredVendors$: Observable<Vendor[]>;
    filteredContainers$: Observable<Container[]>;

    // Subscriptions
    scaleSubscription: Subscription = null;
    scaleWeightSubscription: Subscription = null;
    tareWeightSubscription: Subscription = null;
    tareSubscription: Subscription = null;
    volumeSubscription: Subscription = null;

    // Form state
    success: boolean = false;

  // Validators
  getLocationValidator(): ValidatorFn {
    return (control: FormGroup): ValidationErrors | null => {
      const included = this.ls.cachedLocations.some((x) => x.name === control.value)
      return included ? null : { unknownValue: { value: control.value } };
    };
  };

  getWasteTypeValidator(): ValidatorFn {
    return (control: FormGroup): ValidationErrors | null => {
      const included = this.wts.cachedWaste_Types.some((x) => x.name === control.value)
      return included ? null : { unknownValue: { value: control.value } };
    };
  };

  getCollectorValidator(): ValidatorFn {
    return (control: FormGroup): ValidationErrors | null => {
      const included = this.collrs.cachedCollectors.some((x) => x.name === control.value)
      return included ? null : { unknownValue: { value: control.value } };
    };
  };

  getVendorValidator(): ValidatorFn {
    return (control: FormGroup): ValidationErrors | null => {
      const included = this.vendors.cachedVendors.some((x) => x.name === control.value)
      return included ? null : { unknownValue: { value: control.value } };
    };
  };

  getContainerValidator(): ValidatorFn {
    return (control: FormGroup): ValidationErrors | null => {
      const included = this.conts.cachedContainers.some((x) => x.name === control.value)
      return included ? null : { unknownValue: { value: control.value } };
    };
  };

  getWeighedAtValidator(): ValidatorFn {
    return (control: FormGroup): ValidationErrors | null => {
      // Empty values are allowed
      if (!control.value) return null;

      const tzoffset = (new Date()).getTimezoneOffset() * 60000; // offset in milliseconds
      // Slice the trailing "Z" off the end of the string because this is not UTC
      const now = new Date(Date.now() - tzoffset).toISOString().slice(0, -1);
      const withinRange = control.value < now;
      return withinRange ? null : { max: { value: control.value } }
    }
  }

  getNetWeightValidator(): ValidatorFn {
    return (control: FormGroup<number>): ValidationErrors | null => {
      if (this.usingVolume) {
        return null;
      }

      return control.value >= 0 ? null : { min: { min: 0, actual: control.value } };
    };
  }

  constructor(
      public ls: LocationService,
      public dialog: MatDialog,
      public ss: ScaleService,
      public conts: ContainerService,
      public acs: AccountService,
      public wts: WasteTypeService,
      public collrs: CollectorService,
      public vendors: VendorService,
      private cs: CollectService
  ) {}

  ngOnInit(): void {

    // Check authentication
    this.isLoggedIn();

    this.usingVolume = this.acs.currentAccountValue.default_measurement_type === "volume";

    // volume is always disabled, and will only be set programmatically when the container changes
    this.collectForm.controls.volume.disable();

    // Get the scale weight from the API and update the scale weight value
    this.updateScaleWeight();

    // Update the net weight based on the scale weight and current tare value
    this.updateNetWeight();

    this.filteredCollectLocations$ = combineLatest(
      this.collectForm.controls.collect_location.valueChanges.pipe(
        startWith('')),
        this.ls.collectLocations,
      )
      .pipe(
      map(([value, collectLocations]) => {
        const filtered = value ? this._filteredCollectLocations(value, collectLocations) : collectLocations;
        return filtered.sort((a, b) => (a.floor > b.floor) ? 1 : (a.floor === b.floor) ? ((a.name > b.name) ? 1 : -1) : -1 );
      })
    );

    this.filteredWaste_Types$ = combineLatest(
      this.collectForm.controls.waste_type.valueChanges
      .pipe(
        startWith('')),
        this.wts.waste_types,
      )
      .pipe(
        map(([value, waste_types]) => {
          const filtered = value ? this._filteredWasteTypes(value, waste_types) : waste_types;
          return filtered;
      })
    );

    this.filteredCollectors$ = combineLatest(
      this.collectForm.controls.collector.valueChanges
      .pipe(
        startWith('')),
        this.collrs.collectors,
      )
      .pipe(
        map(([value, collectors]) => {
          const filtered = value ? this._filteredCollectors(value, collectors) : collectors;
          return filtered;
      })
    );

    this.filteredVendors$ = combineLatest(
      this.collectForm.controls.vendor.valueChanges
      .pipe(
        startWith('')),
        this.vendors.vendors,
      )
      .pipe(
        map(([value, vendors]) => {
          const filtered = value ? this._filteredVendors(value, vendors) : vendors;
          return filtered;
      })
    );

    this.filteredContainers$ = combineLatest(
      this.collectForm.controls.container.valueChanges
      .pipe(
        startWith('')),
        this.conts.containers,
      )
      .pipe(
        map(([value, containers]) => {
          const filtered = value ? this._filteredContainers(value, containers) : containers;

          if (this.usingVolume) {
            return filtered.filter((container) => !!container.volume_unit);
          }

          return filtered;
      })
    );

    this.updateTare();
    this.updateVolume();
    this.getScaleUnit();
  }

    updateScaleWeight(): void {
        this.scaleSubscription = this.ss.getScaleWeight()
          .subscribe((scale)=>{
          this.scaleValue$ = scale;
          this.collectForm.controls['scale_weight'].setValue(Math.round((this.scaleValue$)*100)/100);
        });
    }

    isLoggedIn() {
      return this.acs.currentAccount.toPromise();
    }

    getScaleUnit() {
      let scale_unit = "lbs";
      return scale_unit;
    }

    containerUnitLabel() {
      const controls = this.collectForm.controls;
      const container = this.conts.cachedContainers.find((x) => x.name === controls.container.value);
      if (container) {
        return getLabel(container.volume_unit);
      }
    }

    updateNetWeight(): void {
      this.scaleWeightSubscription = this.collectForm.get('scale_weight').valueChanges
        .subscribe((scale: number) => {
          this.scale_weight = scale;
          this.collectForm.controls['net_weight'].setValue(Math.round((this.scale_weight - this.tare_weight)*100)/100);
        });
      this.tareWeightSubscription = this.collectForm.get('tare_weight').valueChanges
        .subscribe((tare: number) => {
          this.tare_weight = tare;
          this.collectForm.controls['net_weight'].setValue(Math.round((this.scale_weight - this.tare_weight)*100)/100);
        });
    }

    updateTare() {
      this.tareSubscription = this.collectForm.controls['container'].valueChanges
        .subscribe(containerNameChange => {
          const container = this.conts.getContainerByName(containerNameChange);
          if (container) {
            this.collectForm.controls['tare_weight'].setValue(container.tare_weight);
          }
        });
    }

    updateVolume() {
      this.volumeSubscription = this.collectForm.get('container').valueChanges
        .subscribe((containerName) => {
          const container = this.conts.getContainerByName(containerName);
          if (container) {
            this.collectForm.controls['volume'].setValue(container.volume);
          }
        });
    }

    // Autocomplete Filters

    private _filteredCollectLocations(value: string, collectLocations: CollectLocation[]): CollectLocation[] {
      const filterValue = value.toLowerCase();
      return collectLocations.filter(collectLocation => collectLocation.name.toLowerCase().indexOf(filterValue) === 0);
    }

    private _filteredWasteTypes(value: string, waste_types: WasteType[]): WasteType[] {
      const filterValue = value.toLowerCase();
      return waste_types.filter(waste_type => waste_type.name.toLowerCase().indexOf(filterValue) === 0);
    }

    private _filteredCollectors(value: string, collectors: Collector[]): Collector[] {
      const filterValue = value.toLowerCase();
      return collectors.filter(collector => collector.name.toLowerCase().indexOf(filterValue) === 0);
    }

    private _filteredVendors(value: string, vendors: Vendor[]): Vendor[] {
      const filterValue = value.toLowerCase();
      return vendors.filter(vendor => vendor.name.toLowerCase().indexOf(filterValue) === 0);
    }

    private _filteredContainers(value: string, containers: Container[]): Container[] {
      const filterValue = value.toLowerCase();
      return containers.filter(container => container.name.toLowerCase().indexOf(filterValue) === 0);
    }

    getCollectFromForm(form: CollectFormGroup) {
      // `getRawValue` instead of just `value` because the volume field is disabled and not included in `value`
      const values = form.getRawValue();

      // If weighed_at was left blank, default to right now
      const weighed_at = values.weighed_at ? Date.parse(values.weighed_at) : Date.now();

      const container = this.conts.cachedContainers.find((x) => x.name === values.container);

      const targetQuantity = this.usingVolume ? values.volume : values.net_weight;

      const collect: CollectInput = {
        container_id: this.conts.idFromContainerName(values.container),
        location_id: this.ls.idFromLocationName(values.collect_location),
        waste_type_id: this.wts.idFromWaste_TypeName(values.waste_type),
        net_weight: values.net_weight,
        collector_id: this.collrs.idFromCollectorName(values.collector),
        vendor_id: this.vendors.idFromVendorName(values.vendor),
        scale_weight: values.scale_weight,
        tare_weight: values.tare_weight,
        measurement_type: this.usingVolume ? "volume" : "weight",
        target_unit: this.usingVolume ? container.volume_unit : Units.LB,
        target_quantity: targetQuantity,
        net_weight_from_volume: this.usingVolume ? this.weightFromVolume(values.volume) : null,
        weighed_at: weighed_at / 1000, // Server is expecting unix seconds, not milliseconds
      }

      return collect;
    }

    collectFormReset() {
      this.collectNgForm.resetForm();
    }

    async onSubmit() {
      const collect = this.getCollectFromForm(this.collectForm);
      if (!this.cs.collectIsValid(collect)) {
        console.error("Tried to submit an invalid collect. Not sending to the server.", collect);
        return;
      } else {
        if (this.cs.isOnline) {
          try {
            await this.cs.submitCollect(collect).toPromise();
            this.openCollectedDialog()
            this.success = true;
          } catch(err) {
            console.error(err);
          }
        } else {
          this.cs.addToQueue(collect);
          this.openQueuedDialog();
        }
      }

      // Reset the form. These are the same as the defaults when the form is initialized.
      // The `reset` documentation states that all fields are automatiicaly set back to their default values,
      // but that doesn't seem to be the case so we're explicitly setting them here.
      this.collectForm.reset({
        collect_location: null,
        waste_type: null,
        collector: null,
        vendor: null,
        container: null,
        tare_weight: null,
        scale_weight: 0,
        net_weight: 0,
        volume: 0,
        weighed_at: "",
      });
    }

    openCollectedDialog() {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.hasBackdrop = true;
      dialogConfig.width = "16rem";
      dialogConfig.height = "20rem";
      dialogConfig.closeOnNavigation = true;
      const dialogRef = this.dialog.open(CollectedComponent, dialogConfig);
      setTimeout(function(){
        dialogRef.close();
      }, 8000); // stay open for 8 seconds
    }

    openQueuedDialog() {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.hasBackdrop = true;
      dialogConfig.width = "16rem";
      dialogConfig.height = "20rem";
      dialogConfig.closeOnNavigation = true;
      const dialogRef = this.dialog.open(QueuedComponent, dialogConfig);
      setTimeout(function(){
        dialogRef.close();
      }, 8000); // stay open for 8 seconds
    }

    toggleWeighedAtContainer() {
      this.weighedAtContainerExpanded = !this.weighedAtContainerExpanded;
      // Reset the input value when the UI is expanded or collapsed. This will help make it clear
      // that the custom weighed_at date is only submitted with the collect when this UI is expanded.
      this.collectForm.controls.weighed_at.setValue("");
    }

    weighedAtTooltipText() {
      const now = new Date();
      return format(now, "MM/dd/yyyy hh:mm aa"); 
    }

    handleUseVolumeChanged(e: MatSlideToggleChange) {
      this.usingVolume = e.checked;

      if (this.usingVolume) {
        const controls = this.collectForm.controls;
        const currentContainer = this.conts.cachedContainers.find((x) => x.name === controls.container.value);
        // If the currently selected container does not have volume, de-select it
        if (currentContainer && !currentContainer.volume_unit) {
          this.collectForm.controls['container'].setValue("");
        }

        // Net weight needs to be revalidated because it's validator function depends on usingVolume
        this.collectForm.controls.net_weight.updateValueAndValidity();
      }
    }

    netWeightToVolume(netWeight: number) {
      const controls = this.collectForm.controls;
      const container = this.conts.cachedContainers.find((x) => x.name === controls.container.value);
      const wasteType = this.wts.cachedWaste_Types.find((x) => x.name === controls.waste_type.value);

      if (!container || !wasteType) {
        return 0;
      }

      const volumePerWeight = wasteType.conversion_volume / wasteType.conversion_weight;
      const containerUnitsPerWasteTypeUnit = getVolumeRatio(
        container.volume_unit,
        wasteType.conversion_volume_unit,
      )

      return netWeight * volumePerWeight * containerUnitsPerWasteTypeUnit;
    }

    weightFromVolume(volume: number) {
      const controls = this.collectForm.controls;
      const container = this.conts.cachedContainers.find((x) => x.name === controls.container.value);
      const wasteType = this.wts.cachedWaste_Types.find((x) => x.name === controls.waste_type.value);

      if (!container) {
        throw("weightFromVolume: no container is selected");
      }
      if (!wasteType) {
        throw("weightFromVolume: no wasteType is selected");
      }

      const weightPerVolume = wasteType.conversion_weight / wasteType.conversion_volume;
      const wasteTypeUnitsPerContainerUnit = getVolumeRatio(
        wasteType.conversion_volume_unit,
        container.volume_unit,
      )

      return volume * wasteTypeUnitsPerContainerUnit * weightPerVolume;
    }

    ngOnDestroy(){
      this.scaleSubscription?.unsubscribe();
      this.scaleWeightSubscription?.unsubscribe();
      this.tareWeightSubscription?.unsubscribe();
      this.tareSubscription?.unsubscribe();
    }
}