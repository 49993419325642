import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Collect, CollectInput } from '../models/collects.model';
import { ApiService } from './api.service';
import { NetworkService } from './network.service';

type QueueType = Record<string, CollectInput>;
type QueueKey = string;

@Injectable({
  providedIn: 'root'
})
export class CollectService {
  private readonly QUEUE_CACHE_KEY = "collection_queue";

  public isOnline: boolean = true;
  private isOnlineSubscription: Subscription = null;

  // Represents an enqueued request to update or delete the last collect.
  // We don't include this in the main localstorage queue because
  // those only represent brand new collects, and there can only be 0 or 1 of these.
  private queuedUpdateOrDelete: (
    | { action: "update", id: number, input: CollectInput }
    | { action: "delete", id: number }
    | null
  ) = null;

  constructor(private api: ApiService, private networkService: NetworkService) {
    this.isOnlineSubscription = this.networkService.isOnline$.subscribe((isOnline) => {
      this.isOnline = isOnline;
      if (isOnline) {
        this.retryQueue();
      }
    });

    // Initialize the queue if necessary
    if (!localStorage.getItem(this.QUEUE_CACHE_KEY)) {
      localStorage.setItem(this.QUEUE_CACHE_KEY, "{}");
    }
  }

  ngOnDestroy() {
    this.isOnlineSubscription?.unsubscribe();
  }

  public lastSavedCollect: [QueueKey, Collect] | null = null;

  public submitCollect(collect: CollectInput) {
    const queueKey = this.addToQueue(collect);

    return this.api.recordCollect(collect).pipe(
      map((collect) => {
        this.lastSavedCollect = [queueKey, collect];
        this.removeFromQueue(queueKey);
        return collect;
      })
    );
  }

  private async retryQueue() {
    if (this.queuedUpdateOrDelete) {
      if (this.queuedUpdateOrDelete.action === "update") {
        await this.api.patchCollect(this.queuedUpdateOrDelete.id, this.queuedUpdateOrDelete.input).pipe(
          map((updatedCollect) => {
            this.queuedUpdateOrDelete = null;
            // Only update lastSavedCollect with this value if the main queue is empty,
            // because otherwise this is not actually the last collect.
            if (Object.entries(this.getQueue()).length === 0) {
              this.lastSavedCollect[1] = updatedCollect;
            }
          })
        ).toPromise();
      } else {
        await this.api.deleteCollect(this.queuedUpdateOrDelete.id).pipe(
          map(() => {
            this.queuedUpdateOrDelete = null;
            // Only clear lastSavedCollect if the main queue is empty,
            // because otherwise this was not actually the last collect.
            if (Object.entries(this.getQueue()).length === 0) {
              this.lastSavedCollect = null;
            }
          })
        ).toPromise();
      }
    }
    const q = this.getQueue();
    Object.keys(q).forEach(key => {
      const collect = q[key];
      if (this.collectIsValid(collect)) {
        this.api.recordCollect(collect).subscribe((_result) => {
          this.removeFromQueue(key);
        });
      }
      else {
        console.error("Tried to record an invalid queued collect. Not sending to the server.", collect);
        this.removeFromQueue(key);
      }
    });
  }

  public addToQueue(collect: CollectInput) {
    const q = this.getQueue();
    const key = this.generateId();
    q[key] = collect;
    this.setQueue(q);
    return key;
  }

  public updateLastCollect(collectInput: CollectInput) {
    if (!this.lastSavedCollect) {
      console.error("Tried to update last collect, but there is no last collect");
      return;
    }

    const [lastCollectQueueKey, lastCollect] = this.lastSavedCollect;
    const queue = this.getQueue();

    const lastSavedCollectStillInQueue = !!queue[lastCollectQueueKey];
    if (lastSavedCollectStillInQueue) {
      // If the last saved collect is still in the queue, we need to update it in place.
      const patchedQueue: QueueType = {
        ...queue,
        [lastCollectQueueKey]: collectInput,
      }
      this.setQueue(patchedQueue);
    } else {
      // Otherwise, we need to enqueue an update and then try running it
      this.queuedUpdateOrDelete = {
        action: "update",
        id: lastCollect.id,
        input: collectInput,
      };
      return this.api.patchCollect(lastCollect.id, collectInput).pipe(
        map((updatedCollect) => {
          this.lastSavedCollect[1] = updatedCollect;
          this.queuedUpdateOrDelete = null;
          return updatedCollect;
        })
      ).toPromise();
    }
  }

  public deleteLastCollect() {
    if (!this.lastSavedCollect) {
      console.error("Tried to delete last collect, but there is no last collect");
      return;
    }

    const [lastCollectQueueKey, lastCollect] = this.lastSavedCollect;
    const queue = this.getQueue();

    // Immediately set lastSavedCollect to null, whether we're removing the
    // collect from the main queue or telling the server to delete it.
    this.lastSavedCollect = null;

    const lastSavedCollectStillInQueue = !!queue[lastCollectQueueKey];
    if (lastSavedCollectStillInQueue) {
      // If the last saved collect is still in the queue, we need to remove it.
      const patchedQueue: QueueType = { ...queue };
      delete patchedQueue[lastCollectQueueKey];
      this.setQueue(patchedQueue);
    } else {
      // Otherwise, we need to enqueue a deletion and then try running it
      this.queuedUpdateOrDelete = {
        action: "delete",
        id: lastCollect.id,
      };
      return this.api.deleteCollect(lastCollect.id).pipe(
        map(() => {
          this.queuedUpdateOrDelete = null;
        })
      ).toPromise();
    }
  }

  private removeFromQueue(key: string) {
    const q = this.getQueue();
    if (!q[key]) {
      console.error(`Tried to delete an entry from collect queue that doesn't exist (${key})`);
      return;
    }
    delete q[key];
    this.setQueue(q);
  }

  private getQueue() {
    const queueSrc = localStorage.getItem(this.QUEUE_CACHE_KEY);
    return JSON.parse(queueSrc) as QueueType;
  }

  private setQueue(queue: QueueType) {
    const strigifiedQueue = JSON.stringify(queue);
    localStorage.setItem(this.QUEUE_CACHE_KEY, strigifiedQueue);
  }

  // Generates a random string of 8 hexadecimal characters
  private generateId(): QueueKey {
    var arr = new Uint8Array((8) / 2);
    window.crypto.getRandomValues(arr);
    return Array.from(arr, (dec) => dec.toString(16).padStart(2, "0")).join("");
  }

  public collectIsValid(c: CollectInput) {
    if (
      c.container_id &&
      c.location_id &&
      c.waste_type_id &&
      c.collector_id &&
      c.weighed_at &&
      c.net_weight !== undefined &&
      c.net_weight !== null &&
      c.scale_weight !== undefined &&
      c.scale_weight !== null &&
      c.tare_weight !== undefined &&
      c.tare_weight !== null
    ) {
      return true;
    }
    return false;
  }
}
