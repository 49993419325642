import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-queued',
  templateUrl: './queued.component.html',
  styleUrls: ['./queued.component.scss']
})

export class QueuedComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
