import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { BehaviorSubject, Observable, from } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { Account } from '../models/account.model';
import { ApiService } from './api.service';
import firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root'
})

export class AccountService implements CanActivate {
  // For `_currentAccount`, `undefined` means the initial authentication request has not completed yet,
  // and `null` means the request completed and the user is not authenticated.
  private _currentAccount = new BehaviorSubject<Account | null | undefined>(undefined);
  private _firebaseApp: firebase.app.App;
  private _api: ApiService;
  private _jwt_rotation_interval: ReturnType<typeof setInterval>;

  authError: string | undefined;

  constructor(private router: Router, private api: ApiService) {
    this._api = api;
    this._firebaseApp = firebase.initializeApp({
      apiKey: "AIzaSyDUdKDWPiOIUOnTjOo3ijTNoLCtQ8rG0_w",
      authDomain: "rightbin-357421.firebaseapp.com",
      projectId: "rightbin-357421",
      storageBucket: "rightbin-357421.appspot.com",
    });

    this._firebaseApp.auth().onAuthStateChanged(async () => {
      const { currentUser } = this._firebaseApp.auth();
      if (currentUser) {
        const jwt = await currentUser.getIdToken();
        const email = currentUser.email;
        this._api.authenticate({ jwt, email })
          .pipe(
            map((account) => {
              this._currentAccount.next(account);
              router.navigate(['/collect_form']);
            })
          )
          .pipe(catchError(() => {
            // Clear out the Firebase JWT
            this._firebaseApp.auth().signOut().then(() => {
              this.authError = "API authentication failed using Firebase credentials";
            });
            return null;
          }))
          .subscribe();
      } else {
        this._currentAccount.next(null);
      }
    });

    // Occasionally get a new JWT from Firebase so that we're never working with an expired token
    this._jwt_rotation_interval = setInterval(async () => {
      const { currentUser } = this._firebaseApp.auth();
      if (currentUser) {
        const forceRefresh = true;
        const jwt = await currentUser.getIdToken(forceRefresh);
        const email = currentUser.email;
        // Immediately hit the Rails server so the new JWT is stored in the session cookie
        this._api.authenticate({ jwt, email }).subscribe();
      }
    }, 1000 * 60 * 15); // 15 minutes in milliseconds
  }

  ngOnDestroy() {
    clearInterval(this._jwt_rotation_interval);
  }

  get currentAccount(): Observable<Account | null> {
    return this._currentAccount.asObservable();
  }

  get currentAccountValue(): Account | null {
    return this._currentAccount.value ?? null;
  }

  get firebaseApp() {
    return this._firebaseApp;
  }

  public signOut() {
    const promise = this._firebaseApp.auth().signOut().then(() => {
      const observable = this.api.logout();
      // No need to unsubscribe
      observable.subscribe(() => {
        this._currentAccount.next(null);
        this.router.navigate(['/']);
      });
    })
    return from(promise);
  }

  canActivate(_route, _state) {
    return this._currentAccount
      .pipe(filter((account => {
        // The initial value of this BehaviorSubject is `undefined` - ignore it
        return account !== undefined;
      })))
      .pipe(map(account => {
        if (account) {
          return true;
        }
        else {
          this.router.navigate(['/login']);
          return false;
        }
      }));
  }
}
