// These constants need to stay consistent with `unit.rb`

export const LB = "lb";
export const KG = "kg";

export const WEIGHTS = [
  LB, KG,
] as const;

export const FT2 = "ft2";
export const M2 = "m2";
export const YD3 = "yd3";
export const M3 = "m3";
export const GAL = "gal";
export const L = "l";

export const VOLUMES = [
  FT2, M2, YD3, M3, GAL, L,
] as const;

export const ALL_UNITS = [...WEIGHTS, ...VOLUMES];

export type TUnit = (typeof ALL_UNITS)[number];
export type TVolumeUnit = (typeof VOLUMES)[number];

const UNITS_PER_YD3: Record<TVolumeUnit, number> = {
  yd3: 1,
  ft2: 27 / 12, // ft3 ÷ 12in
  gal: 201.974,
  l: 764.555,
  m2: 0.764555 / 100, // m3 ÷ 100cm
  m3: 0.764555,
}

export const getVolumeRatio = (newUnit: TVolumeUnit, originalUnit: TVolumeUnit): number => {
  const newUnitsPerYd3 = UNITS_PER_YD3[newUnit];
  const originalUnitsPerYd3 = UNITS_PER_YD3[originalUnit];

  return newUnitsPerYd3 / originalUnitsPerYd3;
}

export const getLabel = (unit: TUnit): string => {
  switch (unit) {
    case "ft2":
      return "sq. ft."
    case "gal":
      return "gal."
    case "kg":
      return "kg."
    case "l":
      return "liters"
    case "lb":
      return "lbs."
    case "m2":
      return "sq. m."
    case "m3":
      return "cu. m."
    case "yd3":
      return "cu. yd."
  }
};
