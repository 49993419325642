import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Vendor } from '../models/vendor.model';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class VendorService {
  public cachedVendors: Vendor[] = [];

  constructor(private api: ApiService) { }

  public get vendors() {
    return this.api.getVendors().pipe(
      map((vendors) => {
        this.cachedVendors = vendors;
        console.log(vendors);
        return vendors;
      })
    );
  }

  public idFromVendorName(name: string): number | undefined {
    return this.getVendorByName(name)?.id;
  }

  public getVendorByName(name: string) {
    return this.cachedVendors.find((vendor) => {
      console.log("getVendorByName", vendor.name);
      return vendor.name === name;
    });
  }
}
