import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AccountService } from '../../services/account.service';
import { Account } from '../../models/account.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

buildingLogo:string = "";

  constructor(public http: HttpClient, private accountService: AccountService) {
    this.currentAccountSubscription = this.accountService.currentAccount.subscribe(account => {
      if (account) {
        this.getBuildingLogo(account);
      }
    });
  }

  private currentAccountSubscription: Subscription = null;

  ngOnDestroy() {
    this.currentAccountSubscription?.unsubscribe();
  }

  async getBuildingLogo(account: Account) {
    if (!account) {
      return;
    }

    this.buildingLogo = account.building.photo_url

  }

  ngOnInit() {
  }

}
